export default {
  normal: {
    background: "#812385",
    color: "white",
    transition: "all 0.2s ease-in",
  },
  hover: {
    background: "#c561c9",
  },
};
